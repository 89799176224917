:root {
  --primary: #ff2a6d;
  --secondary: #05d9e8;
  --dark: #1a1a2e;
  --light: #f1faee;
  --accent: #7b2cbf;
}

body {
  background: linear-gradient(45deg, var(--dark), #0f3460);
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.outer_milestone_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 1rem;
  perspective: 1200px;
  width: 100%;
  box-sizing: border-box;
}

.milestone_container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
  padding: 3rem 1rem;
  z-index: 1;
  box-sizing: border-box;
  margin: 0 auto;
}

/* Floating background elements */
.milestone_container::before,
.milestone_container::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  z-index: -1;
  filter: blur(80px);
  opacity: 0.5;
  animation: float 15s infinite alternate ease-in-out;
}

.milestone_container::before {
  background: var(--primary);
  top: -100px;
  left: 10%;
  animation-delay: 0s;
}

.milestone_container::after {
  background: var(--secondary);
  bottom: -100px;
  right: 10%;
  animation-delay: 5s;
}

@keyframes float {
  0% { transform: translate(0, 0) scale(1); }
  50% { transform: translate(50px, 20px) scale(1.2); }
  100% { transform: translate(-50px, -30px) scale(0.8); }
}

/* Card styling */
.milestone_container .card {
  position: relative;
  width: 280px;
  height: 220px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  margin: 2rem;
  box-sizing: border-box;
}

.milestone_container .card:hover {
  height: 380px; /* Increased height to prevent content cutoff */
  width: 300px;
  transform: translateY(-15px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 10;
}

/* Card decoration elements */
.milestone_container .card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at center,
    transparent 30%,
    rgba(255, 255, 255, 0.1) 70%
  );
  transform: rotate(0deg);
  opacity: 0;
  transition: all 0.8s ease;
}

.milestone_container .card:hover::before {
  transform: rotate(180deg);
  opacity: 0.3;
}

/* Image container */
.milestone_container .card .image {
  position: relative;
  width: 100%;
  height: 220px;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
  transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.milestone_container .card .image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: linear-gradient(to top, var(--dark), transparent);
  opacity: 0.7;
  transition: opacity 0.6s ease;
}

.milestone_container .card:hover .image::after {
  opacity: 0.4;
}

.milestone_container .card .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1.2s ease;
}

.milestone_container .card:hover .image img {
  transform: scale(1.1) rotate(2deg);
}

/* Content styling */
.milestone_container .card .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  color: var(--light);
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  visibility: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-height: 160px; /* Ensure content has enough space */
  overflow-y: auto; /* Allow scrolling if content is too long */
}

.milestone_container .card:hover .content {
  transform: translateY(0);
  opacity: 1;
}

.milestone_container .card .content h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--secondary);
  margin-bottom: 1rem; /* Increased spacing */
  padding: 0 0.5rem; /* Added horizontal padding */
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  text-align: center;
}

.milestone_container .card .content h3::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 3px;
  background: var(--primary);
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%) scaleX(0);
  transform-origin: center;
  transition: transform 0.5s ease 0.2s;
}

.milestone_container .card:hover .content h3::before {
  transform: translateX(-50%) scaleX(1);
}

.milestone_container .card .content p {
  text-align: center;
  margin: 1rem 0;
  padding: 0 0.5rem;
  line-height: 1.6;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.5s ease 0.3s;
  width: 100%;
  box-sizing: border-box;
}

.milestone_container .card:hover .content p {
  transform: translateY(0);
  opacity: 1;
}

/* Add a card appear animation */
@keyframes cardAppear {
  0% { opacity: 0; transform: translateY(40px); }
  100% { opacity: 1; transform: translateY(0); }
}

.milestone_container .card {
  animation: cardAppear 0.8s forwards;
  opacity: 0;
}

.milestone_container .card:nth-child(2) {
  animation-delay: 0.2s;
}

.milestone_container .card:nth-child(3) {
  animation-delay: 0.4s;
}

.milestone_container .card:nth-child(4) {
  animation-delay: 0.6s;
}

/* Hover indicator */
.milestone_container .card::after {
  content: '↓';
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  color: var(--light);
  opacity: 0.7;
  animation: bounce 2s infinite;
  pointer-events: none;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateX(-50%) translateY(0); }
  40% { transform: translateX(-50%) translateY(-10px); }
  60% { transform: translateX(-50%) translateY(-5px); }
}

.milestone_container .card:hover::after {
  opacity: 0;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .outer_milestone_container {
    padding: 3rem 0.5rem;
  }
  
  .milestone_container {
    gap: 1rem;
    padding: 2rem 0.5rem;
  }
  
  .milestone_container .card {
    width: 260px;
    height: 200px;
    margin: 1.5rem;
  }
  
  .milestone_container .card:hover {
    height: 340px;
    width: 280px;
  }
  
  .milestone_container .card .image {
    height: 200px;
  }
  
  .milestone_container .card .content {
    padding: 1rem;
    max-height: 140px;
  }
  
  .milestone_container .card .content h3 {
    font-size: 1.3rem;
    margin-bottom: 0.8rem;
  }
  
  .milestone_container .card .content p {
    font-size: 0.9rem;
    margin: 0.8rem 0;
  }
}

/* Extra small screens */
@media screen and (max-width: 480px) {
  .milestone_container {
    padding: 1rem 0;
  }
  
  .milestone_container .card {
    width: 240px;
    height: 180px;
    margin: 1.2rem 0.5rem;
  }
  
  .milestone_container .card:hover {
    height: 320px;
    width: 260px;
  }
  
  .milestone_container .card .image {
    height: 180px;
  }
}

/* Fix for margin overlap issues */
.milestone_container::after {
  content: '';
  display: block;
  width: 100%;
  height: 2rem;
  clear: both;
}