.carousel-root{
    height: 50%;
    width: 60%;
    margin: 5% auto;
}

.carousel .slide img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 0 8px 8px rgb(61, 68, 71) inset;
}
.carousel .control-dots .dot{
    background-color:#a7c5d3;
}

.carousel.carousel-slider .control-arrow{
    background-color: rgb(124, 170, 219);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 8px 8px rgb(88, 94, 96) inset;
    top: 40%;
}

@media screen and (max-width: 750px) {
    .carousel-root{
    height: 100%;
    width: 100%;
    margin: 15% auto;
}
}