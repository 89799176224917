.award_img{
    border: none;
    height:350px;
    width: 350px;
}
.achievements_container
{
    display: flex;
    flex-direction:row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}
.achievements_div
{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.achievement_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    /* height: fit-content; */
    padding: 2%;
    margin: 2%;
    border-radius: 1.2em;
    cursor: pointer;
    background-color:  rgb(61, 72, 83);
    /* -webkit-box-shadow: 0px 29px 38px -15px rgba(0,0,0,0.43);
    -moz-box-shadow: 0px 29px 38px -15px rgba(0,0,0,0.43);
    box-shadow: 0px 29px 38px -15px rgba(0,0,0,0.43); */
    border-bottom: 3px solid #a1bed1;
    border-top-right-radius: 0px;
    transition: 0.5s ease-in-out;
}
.achievement_box:hover{
    -webkit-box-shadow: 0px 29px 38px -15px rgba(0,0,0,0.43);
    -moz-box-shadow: 0px 29px 38px -15px rgba(0,0,0,0.43);
    box-shadow: 0px 29px 38px -15px rgba(0,0,0,0.43);
    transform: scale(1.05);
    transition: 0.5s ease-in-out;
}

.achievement_para{
    font-size: 1.2rem;
    font-family: 'Alkalami', serif;
    font-weight: 600;
    color: rgb(166, 205, 241);
    margin-bottom: 2%;
}
.achievements_container span{
    font-size: 1.2rem;
    font-family: 'Alkalami', serif;
    font-weight: 600;
    color: #0088ff;
    margin-bottom: 2%;
}

@media screen and (max-width:768px) {
    .achievements_container{
        flex-direction: column;
    }
    .award_img{
        height: 250px;
        width: 250px;
    }
    .achievement_box{
        width: 290px;
        margin: 5% auto;
        /* border-radius: 0px;
        border-bottom: none;
        border-top-right-radius: 2em; */
    }
    .achievement_para{
        font-size: 1rem;

    }
}