/* General Styling */
body {
    background: linear-gradient(135deg, #1c2841, #283747); /* Deep gradient background */
    color: #e0e0e0; /* Light text for contrast */
    font-family: 'Roboto', sans-serif; /* Modern font */
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Societies Paragraph */
.societies_p {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    color: #b8c0e7;
    text-align: justify;
    font-size: 1.1rem;
    padding: 2% 4%;
    line-height: 1.8rem;
    letter-spacing: 0.08rem;
    position: relative;
    transition: all 0.5s ease; /* Smooth transitions */
    background: rgba(255, 255, 255, 0.05); /* Subtle background overlay */
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Soft shadow */
}

.societies_p:hover {
    transform: translateY(-5px); /* Lift on hover */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

/* Subheadings */
.societies_subheading, .societies_small_subheading {
    font-family: 'Montserrat', sans-serif;
    color: #a994ee;
    font-weight: 600; /* Slightly bolder */
    letter-spacing: 0.2rem;
    position: relative;
    padding-left: 8%;
    margin-top: 3%;
    transition: color 0.3s ease; /* Smooth color transition */
}

.societies_subheading:hover, .societies_small_subheading:hover {
    color: #ceb9f9; /* Lighter hover color */
    text-shadow: 0 0 10px rgba(173, 156, 255, 0.5); /* Glowing text shadow */
}

.societies_small_subheading {
    font-size: 1.3rem;
    margin-top: 1%;
}

/* Society Sections */
.women_eng, .robotics, .std_branch, .elect_society {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 7% 0 4% 0;
    font-family: 'Raleway', sans-serif;
    color: #0095DA;
    font-size: 1.8rem; /* Slightly larger */
    font-weight: 500;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    transition: color 0.3s ease, transform 0.3s ease;
}

.women_eng:hover, .robotics:hover, .std_branch:hover, .elect_society:hover {
    color: #4ec4f7; /* Brighter hover color */
    transform: scale(1.05); /* Slight scale on hover */
    text-shadow: 0 0 10px rgba(0, 149, 218, 0.7); /* Glowing text shadow */
}

/* Images */
.img1, .img2, .img3, .img4, .img5, .img6, .img7 {
    border-radius: 12px;
    border: none;
    margin: 2%;
    object-fit: cover;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.img1:hover, .img2:hover, .img3:hover, .img4:hover, .img5:hover, .img6:hover, .img7:hover {
    transform: scale(1.03);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}

.img1, .img5 {
    width: 18rem;
    height: 20rem;
    float: left;
}

.img2, .img3, .img6, .img7 {
    width: 25rem;
    height: 18rem;
    float: right;
}

.img4 {
    width: 20rem;
    height: 15rem;
    float: left;
    padding: 0 15px;
}

/* Text Animation */
.ml12 {
    font-weight: 200;
    font-size: 1.8em;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    display: inline-block; /* Essential for animation */
}

.ml12 .letter {
    display: inline-block;
    line-height: 1em;
    animation: fadeInUp 1s ease-out forwards; /* Apply animation */
    opacity: 0; /* Start invisible */
}

/* Keyframes for Animation */
@keyframes fadeInUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Responsive Design */
@media screen and (max-width: 750px) {
    .societies_p {
        flex-direction: column;
        align-items: center;
        padding: 2% 2%;
    }

    .img1, .img2, .img3, .img4, .img5, .img6, .img7 {
        width: 90%; /* Responsive image width */
        height: auto; /* Maintain aspect ratio */
        margin: 5% 0;
        float: none;
    }

    .women_eng, .robotics, .std_branch, .elect_society {
        flex-direction: column; /* Stack on smaller screens */
        text-align: center;
        margin: 5% 0;
    }
}