/* Enhanced Flagship Section */
.flagship {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch; /* Change to stretch for equal height cards */
    padding: 3% 2%;
    margin: 2%;
    perspective: 1000px; /* For 3D effect */
  }
  
  /* Enhanced Flagship Card */
  .flagship_card {
    width: 400px;
    height: fit-content;
    text-align: center;
    padding: 0 0 1.5%;
    margin: 2%;
    border-radius: 16px;
    position: relative;
    background: linear-gradient(145deg, #364350, #2a343f);
    box-shadow: 
      0 15px 35px rgba(0, 0, 0, 0.3),
      0 5px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    overflow: hidden;
    z-index: 1;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  /* Card Top Border Glow */
  .flagship_card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #2085de, #4a75e6, #2085de);
    background-size: 200% 100%;
    animation: gradientBorder 3s linear infinite;
    z-index: 2;
  }
  
  @keyframes gradientBorder {
    0% { background-position: 0% 0%; }
    100% { background-position: 200% 0%; }
  }
  
  /* Hover Effects */
  .flagship_card:hover {
    transform: translateY(-15px) rotateX(5deg);
    box-shadow: 
      0 25px 50px rgba(0, 0, 0, 0.4),
      0 10px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  /* Glow Effect on Hover */
  .flagship_card:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 0%, rgba(32, 133, 222, 0.4), transparent 70%);
    z-index: -1;
    opacity: 0.8;
  }
  
  /* Enhanced Heading */
  .flagship_heading {
    font-size: 1.5rem;
    font-family: 'Orbitron', sans-serif;
    font-weight: 600;
    color: #2085de;
    margin: 8% 0 4%;
    letter-spacing: 1px;
    position: relative;
    text-shadow: 0 0 10px rgba(32, 133, 222, 0.5);
    transition: all 0.3s ease;
  }
  
  .flagship_card:hover .flagship_heading {
    color: #4a9eff;
    text-shadow: 0 0 15px rgba(74, 158, 255, 0.7);
  }
  
  /* Enhanced Content */
  .flagship_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    padding: 3% 8%;
    margin: 2%;
    color: #c6cff9;
    font-size: 1rem;
    line-height: 1.6;
    letter-spacing: 0.05rem;
    opacity: 0.9;
    transition: all 0.3s ease;
  }
  
  .flagship_card:hover .flagship_content {
    color: #d9e1ff;
    opacity: 1;
  }
  
  /* Enhanced Link */
  .flagship_link {
    color: #5e7de3;
    font-size: 1rem;
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 600;
    letter-spacing: 0.08rem;
    padding: 0.6rem 1.5rem;
    border-radius: 25px;
    margin-top: 5%;
    position: relative;
    overflow: hidden;
    display: inline-block;
    background: rgba(32, 133, 222, 0.1);
    transition: all 0.3s ease;
    text-decoration: none;
    border: 1px solid rgba(94, 125, 227, 0.3);
  }
  
  .flagship_link:hover {
    color: #ffffff;
    background: rgba(32, 133, 222, 0.3);
    box-shadow: 0 0 20px rgba(32, 133, 222, 0.5);
    border: 1px solid rgba(94, 125, 227, 0.6);
  }
  
  /* Link Shine Effect */
  .flagship_link::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: all 0.6s ease;
  }
  
  .flagship_link:hover::after {
    left: 100%;
  }
  
  /* Card Image Enhancement - If you have images */
  .flagship_card img {
    width: 100%;
    border-radius: 16px 16px 0 0;
    object-fit: cover;
    transition: all 0.5s ease;
    filter: saturate(0.9) brightness(0.9);
  }
  
  .flagship_card:hover img {
    filter: saturate(1.1) brightness(1.05);
    transform: scale(1.02);
  }
  
  /* Tech Pattern Background */
  .flagship_card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: 
      linear-gradient(135deg, rgba(32, 133, 222, 0.05) 25%, transparent 25%),
      linear-gradient(225deg, rgba(32, 133, 222, 0.05) 25%, transparent 25%),
      linear-gradient(45deg, rgba(32, 133, 222, 0.05) 25%, transparent 25%),
      linear-gradient(315deg, rgba(32, 133, 222, 0.05) 25%, transparent 25%);
    background-size: 20px 20px;
    opacity: 0.5;
    z-index: -1;
  }
  
  /* Add Icon Container if needed */
  .flagship_icon {
    width: 60px;
    height: 60px;
    background: linear-gradient(145deg, #364350, #2a343f);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -30px auto 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 3;
    border: 3px solid rgba(32, 133, 222, 0.3);
    transition: all 0.3s ease;
  }
  
  .flagship_card:hover .flagship_icon {
    transform: rotateY(180deg);
    border: 3px solid rgba(32, 133, 222, 0.6);
  }
  
  /* Responsive Design */
  @media screen and (max-width: 1024px) {
    .flagship_card {
      width: 380px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .flagship_card {
      width: 340px;
      margin: 3% 2%;
    }
    
    .flagship_heading {
      font-size: 1.4rem;
    }
    
    .flagship_content {
      padding: 3% 6%;
      font-size: 0.95rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .flagship_card {
      width: 100%;
      max-width: 320px;
      margin: 5% 2%;
    }
    
    .flagship_heading {
      font-size: 1.3rem;
    }
    
    .flagship_content {
      padding: 3% 5%;
      font-size: 0.9rem;
    }
    
    .flagship_link {
      font-size: 0.9rem;
      padding: 0.5rem 1.3rem;
    }
  }
  
  /* Animation for cards on page load */
  @keyframes cardAppear {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Apply animation to cards with delay */
  .flagship_card:nth-child(1) { animation: cardAppear 0.6s 0.1s ease-out forwards; }
  .flagship_card:nth-child(2) { animation: cardAppear 0.6s 0.3s ease-out forwards; }
  .flagship_card:nth-child(3) { animation: cardAppear 0.6s 0.5s ease-out forwards; }
  .flagship_card:nth-child(4) { animation: cardAppear 0.6s 0.7s ease-out forwards; }
  .flagship_card:nth-child(5) { animation: cardAppear 0.6s 0.9s ease-out forwards; }
  .flagship_card:nth-child(6) { animation: cardAppear 0.6s 1.1s ease-out forwards; }
  
  /* Set initial state for animation */
  .flagship_card {
    opacity: 0;
  }