.react-photo-gallery--gallery img{
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    transition: all 0.3s ease-in-out;
}
.react-photo-gallery--gallery img:hover{
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(89, 97, 100, 0.5);
    
}
.react-photo-gallery--gallery{
    margin: 5% 1%;
    padding: 2%;
    box-sizing: border-box;  
}