/* Add this at the top of your CSS */
:root {
  --gallery-z-index: 999; /* Ensure gallery stays on top */
  --gallery-fallback-bg: rgba(13, 23, 23, 0.95); /* Match your theme */
}

/* Gallery Compatibility Layer */
.gallery-container {
  position: relative;
  isolation: isolate; /* Create new stacking context */
  min-height: 60vh;
  margin: 2rem 0;
  transform-style: preserve-3d; /* Match your 3D theme */
}

/* Force gallery item positioning */
.ReactGridGallery_tile-view div {
  position: relative !important;
  overflow: hidden !important;
  backface-visibility: hidden !important;
  transform: translateZ(0) !important; /* Force hardware acceleration */
}

/* Image fallback handling */
.ReactGridGallery_tile img {
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
  transition: opacity 0.3s ease !important;
}

/* Override inline styles from gallery */
div[style*="position: absolute"] {
  position: relative !important;
  transform: none !important;
}

/* Touch interaction fixes */
.ReactGridGallery_tile {
  touch-action: manipulation !important; /* Prevent scroll takeover */
}

/* Loading state */
.gallery-loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  border: 3px solid var(--gallery-fallback-bg);
  border-top-color: #0095da;
  border-radius: 50%;
  animation: gallery-spin 1s linear infinite;
}

@keyframes gallery-spin {
  to { transform: rotate(360deg); }
}

/* Error fallback */
.gallery-error {
  display: grid;
  place-items: center;
  padding: 2rem;
  background: var(--gallery-fallback-bg);
  border: 2px solid #0095da33;
  border-radius: 1rem;
}

/* Animation compatibility fixes */
@media (prefers-reduced-motion: reduce) {
  .ReactGridGallery_tile * {
    animation: none !important;
    transition: none !important;
  }
}

/* Print fixes */
@media print {
  .ReactGridGallery_tile {
    page-break-inside: avoid !important;
  }
}

/* Add these to your existing animations */
@keyframes galleryEntry {
  from { 
    opacity: 0;
    transform: scale(0.95) rotateX(10deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotateX(0);
  }
}

.gallery-container {
  animation: galleryEntry 1s ease both;
}

/* Add to your existing media queries */
@media screen and (max-width: 750px) {
  .ReactGridGallery_tile {
    width: 100% !important;
    height: auto !important;
    min-height: 200px !important;
  }
  
  .ReactGridGallery_tile img {
    object-position: center center !important;
  }
}

/* Add these to your hover effects */
.ReactGridGallery_tile:hover img {
  transform: scale(1.05) !important;
  filter: brightness(1.1) contrast(1.05) !important;
}

/* Z-index management */
.ReactGridGallery_tile > div {
  z-index: var(--gallery-z-index) !important;
}

/* Add this to your existing gradient background */
body::before {
  z-index: calc(var(--gallery-z-index) - 1); /* Stay behind gallery */
}

/* Force repaint on gallery updates */
.gallery-container {
  will-change: transform, opacity;
}

/* Add this to your dark mode toggle */
.dark-mode-toggle {
  z-index: calc(var(--gallery-z-index) + 1); /* Stay above gallery */
}