
.container {
    width: 100%;
    /* background-color: rgb(118, 174, 196); */
    /* padding:0 5%; */
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}
.works{
    width: 60%;
    border-radius: 2%;
    /* background-color: #75cff992; */
    background-color: rgb(61, 72, 83);
    box-shadow: 0 5px 10px rgba(110, 142, 149, 0.5);
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 1rem;
    /* align-items: center; */
    padding: 2% 2%;
}

.works ul{
    margin-right: 10%;
    padding-left: 0%;
    display: flex;
    flex-direction: column;
}
.works ul li{
    list-style: none;
    width: 100%;
    font-size: 1.7rem;
    font-family: 'Alkalami', serif;
    font-weight: 600;
    margin: 5% -30px;
    display: flex;
    justify-content: center;
    align-items: center;    
    cursor: pointer;
    transition:0.5s ease-in-out ;
}
.works ul li:hover{
        scale: 1.1;
}
.work {
    margin:0px;
}
.active{
    background-color: transparent;
    text-align: center;
    border-bottom-right-radius: 15px;
    color: #2085de;
    border-bottom: 1.5px solid  #0088ff;
    /* box-shadow:#fff; */
    width: 100%;
    transition: 0.01s ease-in-out;
}
.active:hover{
    width: 93%;
}
.work_content{
    /* transition: 5s ease-in-out; */
    position: relative;
    display: flex;
}
.work_img img{
    height: 400px;
    width: 600px;
    border-radius: 5%;
    object-fit: cover;
}

.work_desc{
    background: #4b6e765a;
    width: 400px;
    height: 150px;
     border-bottom-left-radius: 10%;
     z-index: 20;
     position: absolute;
     bottom:3px;
     padding-bottom: 2%;
}
.work_desc p{
    font-size: 1.2rem;
    font-family: montserratmedium;
    padding: 20px 0 0 30px;
    line-height: 26px;
    font-weight: normal;
    color: #fff;
}
@media screen and (max-width:750px) {
    .works{
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 5px 5px rgba(110, 142, 149, 0.5);
    width: 80%;
    }
    .works ul li{
        font-size: 1rem;
        /* width: 100%; */
        margin:5% 0px;
        margin-left: 5%;
        text-align: center;
    }
    .active{
        border-bottom-left-radius: 15px;
        font-size: 1rem;
    }
    .work_img{
        padding: 0px;
    }
    .work_img img{
    position: relative;
    height: 200px;
    max-width:100%;
    border-radius: 5%;
    object-fit: cover;
    }
    .work_desc {
        width: 100%;
        height: 60px;
        display: flex;
        
    }
    .work_desc p{
    font-size: 0.5rem;
    padding: 2px 3px;
    letter-spacing: 0px;
    object-fit: contain;
    text-align: justify;
    line-height: 250%;
    /* font-weight: 200; */
    }

}
/* @media screen and (min-width: 1200px)
{
    .container {
    max-width: 1140px;
}
}
@media screen and (min-width: 992px)
{
    .container {
    max-width: 960px;
    }
}
@media screen and (min-width: 768px)
{
    .container {
    max-width: 720px;
    }
}
@media screen and (min-width: 576px)
{
    .container {
    max-width: 540px;
    }
} */