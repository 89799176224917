/* Executive Committee Section */
.executive_committe {
  margin-top: 10%;
  text-align: center; /* Center the title */
}

.executive_committe h2 {
  font-family: 'Montserrat', sans-serif;
  color: #a994ee;
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  margin-bottom: 3%;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(173, 156, 255, 0.5); /* Glowing text shadow */
  transition: color 0.3s ease;
}

.executive_committe h2:hover {
  color: #ceb9f9;
}

.executive_committe_card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2%; /* Increased gap for better spacing */
  row-gap: 3%;
  justify-items: center;
  padding: 5% 10%;
}

.executive_committe_card .card { /* Add a class for individual cards */
  background: rgba(255, 255, 255, 0.08); /* Subtle card background */
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  width: 100%; /* Ensure cards fill their grid space */
}

.executive_committe_card .card:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}

.executive_committe_card .card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
  object-fit: cover;
}

.executive_committe_card .card h3 {
  font-family: 'Raleway', sans-serif;
  color: #0095DA;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;
}

.executive_committe_card .card h3:hover {
  color: #4ec4f7;
}

.executive_committe_card .card p {
  font-family: 'Open Sans', sans-serif;
  color: #b8c0e7;
  font-size: 1rem;
  line-height: 1.6rem;
}

/* Accordion Styling */
.accordion {
  background-color: #3e4853;
  color: #b8c0e7;
  cursor: pointer;
  padding: 1rem 2rem; /* Increased padding */
  width: 80%;
  text-align: left;
  border: none;
  outline: none;
  font-size: 1.3rem;
  transition: background-color 0.3s ease, border-radius 0.3s ease;
  display: flex;
  justify-content: space-between;
  max-width: 80vw;
  margin: 3% 10% 1%; /* Adjusted margins */
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.accordion:hover {
  background-color: #4a5663;
  border-radius: 12px;
}

.accordion::after { /* Arrow icon */
  content: '\002B';
  font-size: 1.5rem;
  color: #a994ee;
  transition: transform 0.3s ease;
}

.accordion.active::after {
  content: '\002D';
  transform: rotate(180deg);
}

.hide {
  display: none;
  background-color: #353f4a;
  padding: 1rem 2rem;
  width: 80%;
  margin: 0 10% 3%;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Open Sans', sans-serif;
  color: #b8c0e7;
  line-height: 1.6rem;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .executive_committe_card {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
  }

  .executive_committe_card .card {
      width: 90%; /* Responsive card width */
      margin: 1rem 0;
  }

  .accordion, .hide {
      width: 90%;
      margin-left: 5%;
  }
}