  
  .video-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px; 
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .video-gallery .gallery-item {
    position: relative;
    /* max-width: 100%; */
    height: 100%;
    background: rgb(0, 0, 0);
    cursor: pointer;
    /* overflow: hidden; */
    object-fit: cover;
    border-radius: 10px;
    margin: 2rem;
  }
  
  .video-gallery .gallery-item img {
    position: relative;
    display: block;
    width: 100%;
    height:  250px;
    border-radius: 10px;
    /* fit-content: contain; */
    object-fit: fill;
    opacity: 0.8;
    /*transition: opacity .35s, transform .35s;*/
    transition: all 350ms ease-in-out;
    /* transform: translate3d(-23px, 0, 0); */
    /*backface-visibility: hidden;*/
  }
  
  /* .north-cascades-img {
    object-position: 50% 30%;
  } */
  
  .video-gallery .gallery-item .gallery-item-caption {
    padding: 10px;
    font-size: 1em;
    color: #fff;
    text-transform: uppercase;
  }
  
  .video-gallery .gallery-item .gallery-item-caption,
  .video-gallery .gallery-item .gallery-item-caption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-gallery .gallery-item h2 {
    font-weight: 500;
    overflow: hidden;
    padding: 0px;
  }
  
  .video-gallery .gallery-item h2,
  .video-gallery .gallery-item p {
    position: relative;
    margin: 0px;
    z-index: 1;
    pointer-events: none;
  }
  
  .video-gallery .gallery-item p {
    letter-spacing: 1px;
    font-size: 0.8rem;
    padding: 15px 0px;
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(10%, 0, 0);
  }
  
  .video-gallery .gallery-item:hover img {
    opacity: 0.4;
    transform: translate3d(0, 0, 0);
  }
  
  .video-gallery .gallery-item .gallery-item-caption {
    text-align: left;
  }
  
  .video-gallery .gallery-item h2::after {
    content: "";
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0px;
    height: 1px;
    background: #fff;
    transition: transform 0.3s;
    transform: translate3d(-100%, 0, 0);
  }
  
  .video-gallery .gallery-item:hover h2::after {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    width: 50%;
  }
  
  .video-gallery .gallery-item:hover p {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  
  /*
  @media screen and (max-width: 784px) {
    .video-gallery {
      width: 100%;
      padding: 15px;    
  
    }
    .video-gallery .gallery-item {
      width: 95%;
      margin: 0 auto; 
      width: 100%;
    }
  }
  */
