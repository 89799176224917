/* Bangalore Section */
.Bangalore_Section_p {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.8;
    color: #b8c0e7;
    margin: 3rem 0;
    text-align: center;
    padding: 0 5%;
    font-family: 'Montserrat', sans-serif;
    transition: color 0.3s ease, text-shadow 0.3s ease; /* Added text-shadow transition */
}

.Bangalore_Section_p:hover {
    color: #ceb9f9;
    text-shadow: 0 0 10px rgba(173, 156, 255, 0.5);
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
}

.serviceBox {
    width: 320px;
    height: 410px;
    color: var(--main-color);
    text-align: center;
    margin: 2rem;
    position: relative;
    z-index: 1;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    box-sizing: border-box;
    overflow: hidden; /* Added overflow hidden */
}

.serviceBox:hover {
    transform: translateY(-8px) scale(1.02);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.4);
}

.serviceBox:before {
    content: '';
    background: linear-gradient(to bottom, rgb(61, 72, 83) 89%, var(--main-color) 0%);
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 25px;
    z-index: -1;
    border-radius: 10px;
}

.serviceBox .title {
    color: #f3f5f7;
    background: var(--main-color);
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1.5rem 1rem;
    margin: 0 -1rem 1.5rem;
    border-radius: 12px 12px 0 0;
    position: relative;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transition: background 0.3s ease;
    box-sizing: border-box;
}

.serviceBox .title:hover {
    background: linear-gradient(to right, var(--main-color), rgba(0, 0, 0, 0.2));
}

.serviceBox .title:before,
.serviceBox .title:after {
    content: "";
    background: linear-gradient(to top right, transparent 49%, var(--main-color) 50%);
    width: 10px;
    height: 10px;
    opacity: 0.5;
    position: absolute;
    bottom: -10px;
    left: 0;
}

.serviceBox .title:after {
    transform: rotateY(180deg);
    left: auto;
    right: 0;
}

.serviceBox .description {
    color: #b8c0e7;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.755rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 1.6rem;
    margin: 0 1.5rem 2rem;
    text-align: justify;
    transition: color 0.3s ease;
    box-sizing: border-box;
}

.serviceBox .description:hover {
    color: #c8d0f7;
}

.register {
    background: var(--main-color);
    position: absolute;
    bottom: 10px;
    padding: 0; /* Remove padding */
    text-align: center;
    background-color: transparent;
    box-sizing: border-box;
    width: 100%; /* Ensure full width */
}

.register a {
    font-size: 1.6rem;
    position: relative;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    line-height: 1.8;
    font-family: 'Montserrat', sans-serif;
    transition: font-size 0.4s ease, margin-bottom 0.4s ease, color 0.3s ease;
    white-space: nowrap; /* Prevent text wrapping */
    display: inline-block; /* Ensure width is respected */
    padding: 0.5rem 1rem; /* Add some padding */
    min-width: 120px; /* Set a minimum width */
}

.register a:hover {
    font-size: 1.7rem;
    margin-bottom: -5px;
    color: #4ec4f7;
}

.serviceBox.orange {
    --main-color: #e67e22;
}

.serviceBox.purple {
    --main-color: #5faef4e1;
}

.serviceBox.blue {
    --main-color: #3498db;
}

/* Responsive Design */
@media only screen and (max-width: 1199px) {
    .serviceBox {
        margin: 1.5rem;
    }

    .Bangalore_Section_p {
        font-size: 1.2rem;
    }

    .serviceBox {
        width: 90%;
        max-width: 350px;
    }

    .register {
        padding: 0 10%; /* Fixed padding in responsive */
    }
}

/* Additional Effects */
.serviceBox {
    animation: fadeInUp 1s ease-out; /* Add fadeInUp animation */
}

@keyframes fadeInUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.serviceBox .title {
    animation: pulse 2s infinite ease-in-out; /* Add pulse animation to title */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.register a {
    position: relative;
    overflow: hidden;
}

.register a::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.3), transparent);
    transition: left 0.5s ease;
}

.register a:hover::before {
    left: 100%;
}